import React, { Dispatch, SetStateAction, useState } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Modal, notification, Radio, Row } from 'antd'
import { get, map } from 'lodash'
import { DeleteOutlined, FilePdfOutlined, MailOutlined, SaveOutlined, ToolOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import { FORMS, translateRoleToSk, USER_ROLE } from '../../../utils/enums'

import TextField from '../../../atoms/form/TextField'
import validateForm from './validateForm'
import { history } from '../../../utils/history'
// eslint-disable-next-line import/no-cycle
import { deleteUser } from '../../../reducers/usersU/actions'
// eslint-disable-next-line import/no-cycle
import { passwordResetUser } from '../../../reducers/user/userActions'
import RadioSelectField from '../../../atoms/form/RadioSelectField'
import InputPasswordField from '../../../atoms/InputPasswordField'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../reducers'
// eslint-disable-next-line import/no-cycle
import UserJobs from '../components/UserJobs'
// eslint-disable-next-line import/no-cycle
import StoreItems from '../../storeItems/StoreItems'
// eslint-disable-next-line import/no-cycle
import UserPermissions from '../components/UserPermissions'
// eslint-disable-next-line import/no-cycle
import SelectPriceOfferHeaderOptionField, { ISelectedFirmHeaderOption } from '../../../components/SelectPriceOfferHeaderOptionField'

type ComponentProps = {
	isCreate: boolean
	userID: number | null | undefined
	trusteePopup: boolean
	setShowPasswordChangeModal: Dispatch<SetStateAction<any>>
	onRefreshPage: () => void
}

export interface IUserForm {
	firstName: string
	lastName: string
	email: string
	password: string|null
	phone: string
	lastLoginAt: string
	role: USER_ROLE
}

type Props = InjectedFormProps<IUserForm, ComponentProps> & ComponentProps

const UserForm = (props: Props) => {
	const {
		handleSubmit,
		invalid,
		pristine,
		isCreate,
		setShowPasswordChangeModal,
		trusteePopup,
		userID,
		onRefreshPage
	} = props

	const { t } = useTranslation('components')
	const dispatch = useDispatch()

	const fieldValues: any = useSelector((state: RootState) => getFormValues(FORMS.USER_FORM)(state))

	const profile = useSelector((state: RootState) => state.profile.profile.data)
	const [headerValue, setHeaderValue] = useState<ISelectedFirmHeaderOption | null | undefined>()
	const [isVisibleHandoverRecordModal, setIsVisibleHandoverRecordModal] = useState(false)

	const detail = useSelector((state: RootState) => state.users.detail)

	const handleRemove = () => {
		Modal.confirm({
			title: t('pages:Skutočne si prajete vymazať používateľa?'),
			icon: <DeleteOutlined/>,
			okText: t('pages:Vymazať'),
			cancelText: t('pages:Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(deleteUser(get(fieldValues, 'id'), () => {
					history.push(t('paths:users|path'))
				}))
			}
		})
	}

	const handleCreateHandoverRecord = (userId: number, optionHeader: ISelectedFirmHeaderOption) => {
		const domain = process.env.REACT_APP_API_BASE_URL || 'https://api.umyjeme.top' || undefined
		const pdfURL = `${domain}/api/admin/users/${userId}/serve/hand-over-record/pdf?optionHeaderId=${optionHeader?.value}&currentOrganization=${profile?.currentOrganization?.id}`
		window.open(pdfURL, '_blank')
		setIsVisibleHandoverRecordModal(false)
	}

	const handlePasswordReset = () => {
		Modal.confirm({
			title: t('pages:Odoslať obnovovací email?'),
			icon: <MailOutlined/>,
			okText: t('pages:Odoslať'),
			cancelText: t('pages:Zrušiť'),
			onOk: () => {
				dispatch(passwordResetUser(fieldValues?.email))
			}
		})
	}

	return (
		<Form layout="vertical" className="w-full mt-6" onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ trusteePopup ? 24 : 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						{!trusteePopup ? <h1>{ t('pages:Detail používateľa') }</h1> : <></>}
						<Field
							name={ 'firstName' }
							component={ TextField }
							label={ t('components:Meno') }
						/>
						<Field
							name={ 'lastName' }
							component={ TextField }
							label={ t('components:Priezvisko') }
						/>
						<Field
							name={ 'email' }
							component={ TextField }
							label={ t('components:Kontaktný email') }
						/>
						<Field
							name={ 'phone' }
							component={ TextField }
							label={ t('components:Telefónne číslo') }
						/>
						<Field
							name={ 'role' }
							component={ RadioSelectField }
							label={ t('components:Typ Používateľa') }
							defaultValueKey={ trusteePopup ? USER_ROLE.JOB_TRUSTEE : USER_ROLE.USER}
							required={true}
							options={ map([USER_ROLE.USER, USER_ROLE.JOB_TRUSTEE, USER_ROLE.ADMIN], (role) => (
								<Radio.Button
									key={ role }
									value={ role }
								>
									{ translateRoleToSk(role) }
								</Radio.Button>
							))
							}
						/>
						{ isCreate && (
							<Field
								name={ 'password' }
								component={ InputPasswordField }
								type={ 'password' }
								label={ t('components:Heslo') }
							/>
						) }

						{ trusteePopup && (
							<div className={ 'flex direction-col justify-end' }>
								<Button
									icon={ <SaveOutlined/> }
									onClick={ handleSubmit }
									disabled={ pristine || invalid }
									type={ 'primary' }
									style={ { marginTop: '20px' } }
								>
									{ t('pages:Uložiť') }
								</Button>
							</div>
						) }
					</div>
				</Col>
				{!trusteePopup ? <Col span={ 6 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start sidebar-content' }>
						{ !isCreate && (
							<Field
								name={ 'lastLoginAt' }
								component={ TextField }
								disabled
								label={ t('components:Dátum posledného prihlásenia') }
							/>
						) }

						{ !isCreate && (
							<Field
								name={ 'isConfirmed' }
								component={ TextField }
								label={ t('components:Potvrdenie emailu') }
								disabled
							/>
						) }

						<Field
							name={ 'email' }
							component={ TextField }
							label={ t('components:Registračný email') }
							disabled
						/>

						{ !isCreate && detail?.data?.role === USER_ROLE.USER && (
							<Button
								icon={ <FilePdfOutlined/> }
								onClick={ () => setIsVisibleHandoverRecordModal(true) }
								type={ 'primary' }
								style={ { marginTop: '20px' } }
							>
								{ t('components:Preberací protokol') }
							</Button>
						) }

						{ !isCreate && (
							<Button
								icon={ <SaveOutlined/> }
								onClick={ handlePasswordReset }
								type={ 'primary' }
								style={ { marginTop: '20px' } }
							>
								{ t('pages:Odoslať email na obnovu hesla') }
							</Button>
						) }

						{ !isCreate && (
							<Button
								icon={ <ToolOutlined /> }
								onClick={ () => setShowPasswordChangeModal(true) }
								type={ 'primary' }
								style={ { marginTop: '20px' } }
							>
								{ t('pages:Nastaviť nové heslo') }
							</Button>
						) }

						<div className={ 'flex direction-col justify-center' }>
							<Button
								icon={ <SaveOutlined/> }
								onClick={ handleSubmit }
								disabled={ pristine || invalid }
								type={ 'primary' }
								style={ { marginTop: '20px' } }
							>
								{ t('pages:Uložiť') }
							</Button>

							{ !isCreate && (
								<Button
									icon={ <DeleteOutlined/> }
									onClick={ () => handleRemove() }
									type={ 'primary' }
									style={ { marginTop: '20px' } }
								>
									{ t('pages:Vymazať používateľa') }
								</Button>
							) }
						</div>
					</div>
				</Col> : <></>}
			</Row>
			{userID && fieldValues?.role !== USER_ROLE.JOB_TRUSTEE ? <Row className={'mt-4'} gutter={24}>
				<Col span={24} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<UserJobs
							userID={userID}
						/>
					</div>
				</Col>
			</Row> : <div />
			}
			{ (!isCreate && !trusteePopup && fieldValues?.role !== USER_ROLE.JOB_TRUSTEE) && (
				<Col className={'grid mt-4'}>
					<div className={'flex direction-col justify-start main-content'}>
						<h3 className={'ml-2'}>{t('pages:Osobné skladové položky')}</h3>
						<StoreItems
							tableData={detail.data?.tableData}
							initFilter={{ jobID: null, jobToID: detail.data?.userStoreId }}
							tableMode={true}
							addRemoveItemFeature={true}
						/>
					</div>
				</Col>
			)}
			{userID && <Row className={'mt-4'} gutter={24}>
				<Col span={24} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<UserPermissions
							userID={userID}
							userPermissions={detail.data?.userPermissions}
							onRefresh={onRefreshPage}
						/>
					</div>
				</Col>
			</Row>}
			<Modal
				title={ i18next.t('pages:Vytvoriť preberací protokol') }
				visible={isVisibleHandoverRecordModal}
				okText={i18next.t('pages:Vytvoriť')}
				onOk={() => {
					if (!headerValue) {
						notification.error({
							message: i18next.t('pages:Vyberte hlavičku')
						})
						return
					}
					if (!userID) {
						notification.error({
							message: i18next.t('pages:Požadované ID používateľa')
						})
						return
					}
					handleCreateHandoverRecord(userID, headerValue)
				}}
				onCancel={ () => setIsVisibleHandoverRecordModal(false)} >
				<div className={'mb-4'}>
					<span className={'mr-2'}>{i18next.t('pages:Chýbajúce údaje môžete doplniť v nastaveniach')}</span>
					<Link to={ t('paths:appSettings|path').toString()}>
						{ i18next.t('pages:Nastavenia') }
					</Link>
				</div>
				<SelectPriceOfferHeaderOptionField
					onValueChange={setHeaderValue}
					headerValue={headerValue}
				/>
			</Modal>
		</Form>
	)
}

const form = reduxForm<IUserForm, ComponentProps>({
	form: FORMS.USER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(UserForm)

export default form
