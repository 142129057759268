import React from 'react'
import { Select, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { PRICE_OFFER_STATUS, translatePriceOfferStatusColor, translatePriceOfferStatusToSk } from '../utils/enums'

const { Option } = Select

type Props = {
	onValueChange: (value: PRICE_OFFER_STATUS[]) => void,
	statusValues: PRICE_OFFER_STATUS[]
}

const SelectPriceOfferStatus = ({ onValueChange, statusValues }: Props) => {
	const { t } = useTranslation('pages')

	const priceOfferStatus = [
		{
			label: translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.NOT_ACCEPTED),
			value: PRICE_OFFER_STATUS.NOT_ACCEPTED
		},
		{
			label: translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.DECLINED),
			value: PRICE_OFFER_STATUS.DECLINED
		},
		{
			label: translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.FINISHED),
			value: PRICE_OFFER_STATUS.FINISHED
		},
		{
			label: translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.PRICE_INCREASE),
			value: PRICE_OFFER_STATUS.PRICE_INCREASE
		},
		{
			label: translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED),
			value: PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED
		}
	]

	return (
		<Select
			mode="multiple"
			allowClear
			style={{ minWidth: 200, maxWidth: 'fit-content' }}
			placeholder={t('Vyber stav cenovej ponuky')}
			value={statusValues}
			onChange={onValueChange}
			tagRender={({ label, value, closable, onClose }) => (
				<Tag
					color={translatePriceOfferStatusColor(value as PRICE_OFFER_STATUS)}
					closable={closable}
					onClose={onClose}
					style={{ marginBottom: 2 }}
				>
					{label}
				</Tag>
			)}
		>
			{priceOfferStatus.map((status) => (
				<Option key={status.value} value={status.value}>
					{status.label}
				</Option>
			))}
		</Select>
	)
}

export default React.memo(SelectPriceOfferStatus)
