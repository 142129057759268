import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { createSlug } from '../utils/helper'
// eslint-disable-next-line import/no-cycle
import { getPriceOfferHeaderOptions, IFirmHeader } from '../reducers/priceOffers/actions'

const { Item } = Form
const { Option } = Select

type Props = {
	onValueChange: (value: ISelectedFirmHeaderOption) => void,
	headerValue: ISelectedFirmHeaderOption | undefined | null
}

export interface ISelectedFirmHeaderOption {
	value: number,
	label: string
}

const SelectPriceOfferHeaderOptionField = (props: Props) => {
	const { onValueChange, headerValue } = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [headerSelected, setHeaderSelected] = useState<ISelectedFirmHeaderOption | undefined>(undefined)
	const [headerOptions, setHeaderOptions] = useState<ISelectedFirmHeaderOption[]>([])

	function onChange(value: number, option: any) {
		onValueChange({
			value: option?.value,
			label: option?.children
		})
		setHeaderSelected(option?.value)
	}

	useEffect(() => {
		dispatch(getPriceOfferHeaderOptions((data) => {
			setHeaderOptions(data.headers.map((header: IFirmHeader) => ({
				value: header.id,
				label: header.firmName
			})))
		}))
	}, [])

	useEffect(() => {
		setHeaderSelected(headerOptions?.find((option) => option?.value === headerValue?.value))
	}, [headerValue])

	return (
		<Item>
			<Select
				onBlur={() => {}}
				className={'dropDown'}
				size={'middle'}
				onChange={onChange}
				suffixIcon={<ChevronIcon width={20} height={20} />}
				showSearch
				allowClear={true}
				value={headerSelected?.value}
				style={{ width: 400 }}
				placeholder="Vyber hlavičku"
				optionFilterProp="children"
				filterOption={(input: any, option: any) => createSlug(option?.children).indexOf(createSlug(input)) >= 0}
			>
				{map(headerOptions, (option) => (
					<Option key={option.value} value={option.value}>
						{option.label}
					</Option>
				))}
			</Select>
		</Item>
	)
}

export default React.memo(SelectPriceOfferHeaderOptionField)
