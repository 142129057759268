import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Empty, Form, Input, Modal, notification, Row, Switch, Table, Tag, Tooltip } from 'antd'
import i18next from 'i18next'
import { debounce, get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CloseOutlined, FilePdfOutlined, MailOutlined, PlusCircleOutlined, QrcodeOutlined } from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { useReactToPrint } from 'react-to-print'
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { getFormInitialValues, initialize } from 'redux-form'
import { Link } from 'react-router-dom'
import { getJobs, IQrScansOriginTableItem, ITrusteeTableItem, JobTableItem } from '../../reducers/jobs/actions'
import { history } from '../../utils/history'
import { RootState } from '../../reducers'
import { ComponentToPrint } from '../../components/QrCode/ComponentToPrint'
import { FORM } from '../../utils/enums'
import TableToPrint from '../../components/QrCode/TableToPrint'
import SelectPriceOfferHeaderOptionField, { ISelectedFirmHeaderOption } from '../../components/SelectPriceOfferHeaderOptionField'

const PAGE_SIZE = 40

type Columns = ColumnsType<JobTableItem>

const Jobs = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const [visibleGenerateQrCodeModal, setVisibleGenerateQrCodeModal] = useState(false)
	const [visibleGenerateItemListModal, setVisibleGenerateItemListModal] = useState(false)

	const [isVisibleHandoverRecordModal, setIsVisibleHandoverRecordModal] = useState(false)
	const [isVisibleHandoverMergeRecordModal, setIsVisibleHandoverMergeRecordModal] = useState(false)
	const [headerValue, setHeaderValue] = useState<ISelectedFirmHeaderOption | null | undefined>()

	const jobs = useSelector((state: RootState) => state.jobs.jobs)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.JOBS_FILTER)(state))

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys)
		setSelectedRowKeys(newSelectedRowKeys)
		console.log(newSelectedRowKeys)
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange
	}

	const isLoadingList = jobs.isLoading

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		inflationPartAccepted: withDefault(BooleanParam, null),
		limit: withDefault(NumberParam, PAGE_SIZE),
		page: withDefault(NumberParam, 1),
		orderBy: withDefault(StringParam, 'address'),
		orderDirection: withDefault(StringParam, null)
	})

	const changeInflationFilter = (value: boolean) => { setQuery({ ...query, inflationPartAccepted: value }) }

	const handleCreateHandoverRecord = (jobIds: number[], optionHeader: ISelectedFirmHeaderOption) => {
		const domain = process.env.REACT_APP_API_BASE_URL || 'https://api.umyjeme.top' || undefined
		const pdfURL = `${domain}/api/admin/jobs/serve/hand-over-record/pdf?optionHeaderId=${optionHeader?.value}&${jobIds.map((id) => `jobsIds[]=${id}`).join('&')}`
		window.open(pdfURL, '_blank')
		setIsVisibleHandoverRecordModal(false)
	}

	const handleCreateMergeHandoverRecord = (jobIds: number[], optionHeader: ISelectedFirmHeaderOption) => {
		const domain = process.env.REACT_APP_API_BASE_URL || 'https://api.umyjeme.top' || undefined
		const pdfURL = `${domain}/api/admin/jobs/serve/hand-over-merge-record/pdf?optionHeaderId=${optionHeader?.value}&${jobIds.map((id) => `jobsIds[]=${id}`).join('&')}`
		window.open(pdfURL, '_blank')
		setIsVisibleHandoverRecordModal(false)
	}

	useEffect(() => {
		dispatch(getJobs({
			limit: visibleGenerateItemListModal ? 100000 : query.limit,
			page: query.page,
			queryParams: {
				search: query.search,
				orderBy: query.orderBy,
				orderDirection: query.orderDirection,
				...(query.inflationPartAccepted !== null && { inflationPartAccepted: query.inflationPartAccepted })
			}
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderBy, query.orderDirection, query.inflationPartAccepted, visibleGenerateItemListModal])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.JOBS_FILTER, initData))
		}
		setSelectedRowKeys([])
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const columnsToPrint: Columns = [
		{
			title: i18next.t('pages:Adresa'),
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			width: 110,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Zodpovedné osoby'),
			dataIndex: 'trustees',
			key: 'trustees',
			ellipsis: true,
			width: 250,
			render(value: ITrusteeTableItem[]) {
				return value.map((trustee) => <Tooltip key={trustee.email} placement="topLeft" title={ trustee.sendEmail ? <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} <b style={{ color: 'green' }}>{ i18next.t('pages:Aktívne') }</b> / { i18next.t('pages:Neaktívne') }</div> : <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} { i18next.t('pages:Aktívne') } / <b style={{ color: 'red' }}>{ i18next.t('pages:Neaktívne') }</b></div> }>
					<Tag>
						{`${trustee.email}`}<span> </span>
						<MailOutlined style={ {
							marginRight: 6,
							color: trustee.sendEmail ? 'green' : 'red'
						} }/>
					</Tag>
				</Tooltip>)
			}
		}]

	const columns: Columns = [
		{
			title: i18next.t('pages:Adresa'),
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			width: 110,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Zodpovedné osoby'),
			dataIndex: 'trustees',
			key: 'trustees',
			ellipsis: true,
			width: 250,
			render(value: ITrusteeTableItem[]) {
				return value.map((trustee) => <Tooltip key={trustee.email} placement="topLeft" title={ trustee.sendEmail ? <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} <b style={{ color: 'green' }}>{ i18next.t('pages:Aktívne') }</b> / { i18next.t('pages:Neaktívne') }</div> : <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} { i18next.t('pages:Aktívne') } / <b style={{ color: 'red' }}>{ i18next.t('pages:Neaktívne') }</b></div> }>
					<Tag>
						{`${trustee.email}`}<span> </span>
						<MailOutlined style={ {
							marginRight: 6,
							color: trustee.sendEmail ? 'green' : 'red'
						} }/>
					</Tag>
				</Tooltip>)
			}
		},
		{
			title: i18next.t('pages:Trvanie'),
			dataIndex: 'qrScan',
			key: 'qrScan',
			ellipsis: true,
			align: 'center',
			width: 30,
			render(value: IQrScansOriginTableItem | null) {
				return <div className={'flex justify-center'}>
					<div className={'text-center'}>{value?.duration}</div>
				</div>
			}
		},
		{
			title: i18next.t('pages:Posledná služba'),
			dataIndex: 'qrScan',
			key: 'qrScan',
			ellipsis: true,
			align: 'right',
			width: 30,
			render(value: IQrScansOriginTableItem | null) {
				return <div className={'flex flex-row-reverse mr-3'}>
					<div>
						<div>{value?.from} - {value?.to}</div>
						<div className={'text-gray-400 text-xs text-left'}>{value?.date}</div>
					</div>
				</div>
			}
		}
	]

	const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<JobTableItem> | SorterResult<JobTableItem>[]) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		// @ts-ignore
		setQuery({
			...query,
			limit: PAGE_SIZE,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm }), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	// @ts-ignore
	return (
		<>
			<div className={'page-wrapper'}>
				<div>
					{selectedRowKeys?.length > 0 ? <div className={'flex'} style={{ flexWrap: 'wrap' }}>
						<Card
							hoverable
							className={'w-full max-w-full flex-center'}
							size={'small'}
							style={{
								width: '100%', minHeight: '80px', display: 'flex', justifyContent: 'space-between'
							}}
							extra={ <div className={'flex-center'} style={{ minHeight: '60px', display: 'flex', justifyContent: 'space-between' }}>
								<CloseOutlined
									style={{ fontSize: 30, marginRight: '20px' }}
									onClick={ () => setSelectedRowKeys([]) }
								/>
								<span className={'font-bold'}>{t('pages:Vybrané ')} {selectedRowKeys.length}  </span>
							</div>
							}
						>
							<Button
								icon={ <FilePdfOutlined/> }
								onClick={ () => setIsVisibleHandoverMergeRecordModal(true) }
								type={ 'primary' }
								style={ { marginTop: '20px', marginLeft: '20px', marginRight: '20px' } }
							>
								{ t('components:Zlúčiť preberacie protokoly') }
							</Button>
							<Button
								icon={ <FilePdfOutlined/> }
								onClick={ () => setIsVisibleHandoverRecordModal(true) }
								type={ 'primary' }
								style={ { marginTop: '20px' } }
							>
								{ t('components:Preberací protokol') }
							</Button>
						</Card>
					</div> : <div className={'flex justify-between'} style={{ minHeight: '80px' }}>
						<Row>
							<Form.Item>
								<Input.Search onChange={handleOnChange} style={{ width: 300 }} allowClear />
							</Form.Item>
							<Form.Item
								className={'mr-4 ml-4'}
								label={<span className={'font-semibold'}>{'Len s aktívnou inflačnou doložkou'}</span>}
							>
								<Row>
									<Switch
										onChange={changeInflationFilter}
										checked={query?.inflationPartAccepted || false}
									/>
								</Row>
							</Form.Item>
						</Row>
						<div>
							{query.inflationPartAccepted ? (
								<Button
									icon={<PlusCircleOutlined />}
									className={'mx-4'}
									type={'primary'}
									onClick={() => setVisibleGenerateItemListModal(true)}
								>
									{t('pages:Vytlač zoznam zákaziek')}
								</Button>
							) : <Button
								icon={<QrcodeOutlined />}
								className={'mx-4'}
								type={'primary'}
								onClick={() => setVisibleGenerateQrCodeModal(true)}
							>
								{t('pages:Generuj Qr Kody')}
							</Button>}
							<Button
								icon={<PlusCircleOutlined />}
								href={t('paths:jobCreate|path')}
								type={'primary'}
							>
								{t('pages:Pridať Zákazku')}
							</Button>
						</div>
					</div>}
				</div>
				<Table
					rowSelection={rowSelection}
					className={'general-table'}
					columns={columns}
					dataSource={jobs.tableData}
					onChange={handleTableChange}
					style={{ marginTop: 0 }}
					showSorterTooltip={false}
					pagination={{
						pageSize: PAGE_SIZE,
						total: jobs.pagination?.totalCount,
						current: jobs.pagination?.page,
						showSizeChanger: false
					}}
					loading={isLoadingList}
					onRow={(record) => ({
						onClick: () => history.push(`${t('paths:jobDetail|path')}/${get(record, 'id')}`)
					})}
					locale={{
						emptyText: (
							<Empty description={t('pages:Žiadne dáta')} />
						)
					}}
					size={'small'}
				/>
			</div>
			<Modal
				title={t('pages:Vytlač qr kódy')}
				centered
				visible={visibleGenerateQrCodeModal}
				onOk={() => {
					setVisibleGenerateQrCodeModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateQrCodeModal(false)}
				width={1000}
			>
				<Button
					type={'primary'}
					onClick={handlePrint}
				>
					{t('pages:Vytlačiť')}
				</Button>
				<ComponentToPrint
					ref={componentRef} list={jobs.tableData.map((data) => ({
						name: data.address,
						data: {
							jobID: data.id,
							jobName: data.address
						}
					}))}
				/>
			</Modal>
			<Modal
				title={t('pages:Vytlač zoznam zákaziek')}
				centered
				visible={visibleGenerateItemListModal}
				onOk={() => {
					setVisibleGenerateItemListModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateItemListModal(false)}
				width={1000}
			>
				<Button
					type={'primary'}
					onClick={handlePrint}
				>
					{t('pages:Vytlačiť')}
				</Button>
				<TableToPrint ref={componentRef}>
					<Table
						className={'general-table'}
						columns={columnsToPrint}
						dataSource={jobs.tableData}
						onChange={handleTableChange}
						style={{ marginTop: 0 }}
						showSorterTooltip={false}
						pagination={false}
						locale={{
							emptyText: (
								<Empty description={t('pages:Žiadne dáta')} />
							)
						}}
						size={'small'}
					/>
				</TableToPrint>
			</Modal>
			<Modal
				title={ i18next.t('pages:Vytvoriť preberací protokol') }
				visible={isVisibleHandoverRecordModal}
				okText={i18next.t('pages:Vytvoriť')}
				onOk={() => {
					if (!headerValue) {
						notification.error({
							message: i18next.t('pages:Vyberte hlavičku')
						})
						return
					}
					handleCreateHandoverRecord(selectedRowKeys.map((key: React.Key) => key as number), headerValue)
				}}
				onCancel={ () => setIsVisibleHandoverRecordModal(false)} >
				<div className={'mb-4'}>
					<span className={'mr-2'}>{i18next.t('pages:Chýbajúce údaje môžete doplniť v nastaveniach')}</span>
					<Link to={ t('paths:appSettings|path').toString()}>
						{ i18next.t('pages:Nastavenia') }
					</Link>
				</div>
				<SelectPriceOfferHeaderOptionField
					onValueChange={setHeaderValue}
					headerValue={headerValue}
				/>
			</Modal>
			<Modal
				title={ i18next.t('pages:Vytvoriť zlúčený preberací protokol') }
				visible={isVisibleHandoverMergeRecordModal}
				okText={i18next.t('pages:Vytvoriť')}
				onOk={() => {
					if (!headerValue) {
						notification.error({
							message: i18next.t('pages:Vyberte hlavičku')
						})
						return
					}
					handleCreateMergeHandoverRecord(selectedRowKeys.map((key: React.Key) => key as number), headerValue)
				}}
				onCancel={ () => setIsVisibleHandoverMergeRecordModal(false)} >
				<div className={'mb-4'}>
					<span className={'mr-2'}>{i18next.t('pages:Chýbajúce údaje môžete doplniť v nastaveniach')}</span>
					<Link to={ t('paths:appSettings|path').toString()}>
						{ i18next.t('pages:Nastavenia') }
					</Link>
				</div>
				<SelectPriceOfferHeaderOptionField
					onValueChange={setHeaderValue}
					headerValue={headerValue}
				/>
			</Modal>
		</>
	)
}

Jobs.propTypes = {}

export default Jobs
