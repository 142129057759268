import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Spin } from 'antd'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import moment from 'moment'

import { getPriceOffersStatusPerMonthStats, IPriceOffersStatusPerMonthPayload } from '../../../reducers/statistics/actions'
import { PRICE_OFFER_STATUS, translatePriceOfferStatusToSk } from '../../../utils/enums'

export type StatisticsDashboardSummary = {
	NOT_ACCEPTED: number,
	FINISHED: number,
	DECLINED: number,
	PRICE_INCREASE: number,
	PRICE_INCREASE_DECLINED: number,
}

interface PriceOffersStatusPerMonthProps {
	startDate?: string;
	endDate?: string;
	employeeID?: number | null,
	status?: PRICE_OFFER_STATUS[],
	organizationID?: number | null,
	onSummaryChange?: (summary: StatisticsDashboardSummary) => void
}

const translatePriceOfferStatusColor = (status: PRICE_OFFER_STATUS) => {
	switch (status) {
		case PRICE_OFFER_STATUS.NOT_ACCEPTED:
			return '#48c1ff' // Blue 7 (text)
		case PRICE_OFFER_STATUS.DECLINED:
			return '#ff3310' // Red 7 (text)
		case PRICE_OFFER_STATUS.FINISHED:
			return '#64af02' // Green 7 (text)
		default:
			return '#d9d9d9' // default color if needed
	}
}

interface OfferDetail {
	address: string;
	author: string;
	createdAt: string;
}

interface CustomTooltipPayload {
	name: string;
	value: number;
	color: string;
	dataKey: string;
	payload: {
		date: string;
		[status: string]: any;
		details: {
			[status: string]: OfferDetail[];
		};
	};
}

const isCustomTooltipPayload = (entry: any): entry is CustomTooltipPayload => (
	typeof entry.name === 'string'
	&& typeof entry.value === 'number'
	&& typeof entry.color === 'string'
	&& typeof entry.dataKey === 'string'
	&& entry.payload && typeof entry.payload === 'object'
)

const PriceOffersStatusPerMonth: React.FC<PriceOffersStatusPerMonthProps> = ({
	startDate,
	endDate,
	employeeID,
	status,
	organizationID,
	onSummaryChange
}) => {
	const dispatch = useDispatch()
	const [chartData, setChartData] = useState<any[]>([])
	const [activeStatus, setActiveStatus] = useState<string | null>(null)
	const [selectedStatus, setSelectedStatus] = useState<PRICE_OFFER_STATUS | null>(null)
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null)
	const [selectedDetails, setSelectedDetails] = useState<OfferDetail[]>([])

	useEffect(() => {
		const params = {
			startDate,
			endDate,
			employeeID,
			status,
			organizationID
		}

		dispatch(getPriceOffersStatusPerMonthStats(
			params,
			(data: IPriceOffersStatusPerMonthPayload) => {
				setChartData(data.priceOffersStatusPerMonth)
				if (onSummaryChange) {
					onSummaryChange(
						{
							NOT_ACCEPTED: data.context.NOT_ACCEPTED,
							FINISHED: data.context.FINISHED,
							DECLINED: data.context.DECLINED,
							PRICE_INCREASE: data.context.PRICE_INCREASE,
							PRICE_INCREASE_DECLINED: data.context.PRICE_INCREASE_DECLINED
						}
					)
				}
			},
			(error) => {
				console.error('Failed to fetch data:', error)
			}
		))
	}, [dispatch, startDate, endDate, employeeID, status, organizationID])

	// Move CustomTooltip inside the component to access activeStatus
	const CustomTooltip: React.FC<TooltipProps<string, number>> = ({ active, payload, label }) => {
		if (active && payload && payload.length && activeStatus) {
			const data = payload[0].payload
			// Find the entry that matches the activeStatus
			const entry = payload.find((p) => p.dataKey === activeStatus)
			if (entry && isCustomTooltipPayload(entry)) {
				const status: string = entry.dataKey
				const details = data?.details?.[status] || []
				return (
					<div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
						<p>{`Mesiac: ${label}`}</p>
						<div>
							<p style={{ color: entry.color }}>{`${translatePriceOfferStatusToSk(entry.name)}: ${entry.value}`}</p>
							{details.length > 0 && (
								<ul>
									{details.map((offer: OfferDetail, idx: number) => (
										<li key={idx}>
											<strong>Adresa:</strong> {offer.address}<br />
											<strong>Autor:</strong> {offer.author}<br />
											<strong>Dátum vytvorenia:</strong> {moment(offer.createdAt).format('DD.MM.YYYY')}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				)
			}
			return null
		}
		return null
	}

	const handleBarClick = (data: any, index: number, status: string) => {
		const month = data.date
		const details = data.details?.[status] || []
		// Toggle selection if the same bar is clicked
		if (selectedStatus === status && selectedMonth === month) {
			setSelectedStatus(null)
			setSelectedMonth(null)
			setSelectedDetails([])
		} else {
			setSelectedStatus(status as PRICE_OFFER_STATUS)
			setSelectedMonth(month)
			setSelectedDetails(details)
		}
	}

	return (
		<Card hoverable className={'my-2'}>
			<Spin spinning={false}>
				<div className={'font-bold mb-2'}>Cenové ponuky za mesiac</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<BarChart
						width={600}
						height={350}
						data={chartData}
						margin={{
							top: 10, right: 30, left: 20, bottom: 50
						}} // Zvýšený spodný margin pre legendu
					>
						<Legend />
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" />
						<YAxis />
						<Tooltip content={<CustomTooltip />} />
						<Bar
							name={'Ukončená'}
							dataKey="FINISHED"
							stackId="a"
							fill={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.FINISHED)}
							onMouseOver={() => setActiveStatus('FINISHED')}
							onMouseOut={() => setActiveStatus(null)}
							onClick={(data, index) => handleBarClick(data.payload, index, 'FINISHED')}
						/>
						<Bar
							name={'Neschválená'}
							dataKey="NOT_ACCEPTED"
							stackId="a"
							fill={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.NOT_ACCEPTED)}
							onMouseOver={() => setActiveStatus('NOT_ACCEPTED')}
							onMouseOut={() => setActiveStatus(null)}
							onClick={(data, index) => handleBarClick(data.payload, index, 'NOT_ACCEPTED')}
						/>
						<Bar
							name={'Zamietnutá'}
							dataKey="DECLINED"
							stackId="a"
							fill={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.DECLINED)}
							onMouseOver={() => setActiveStatus('DECLINED')}
							onMouseOut={() => setActiveStatus(null)}
							onClick={(data, index) => handleBarClick(data.payload, index, 'DECLINED')}
						/>
					</BarChart>
					{selectedStatus && selectedMonth && (
						<div style={{ marginTop: '20px', maxWidth: '300px' }}>
							<h3>{`${translatePriceOfferStatusToSk(selectedStatus)} - ${selectedMonth}`}</h3>
							<button onClick={() => { setSelectedStatus(null); setSelectedMonth(null); setSelectedDetails([]) }}>Clear Selection</button>
							{selectedDetails.length > 0 ? (
								<ul>
									{selectedDetails.map((offer: OfferDetail, idx: number) => (
										<li key={idx}>
											<strong>Adresa:</strong> {offer.address}<br />
											<strong>Autor:</strong> {offer.author}<br />
											<strong>Dátum vytvorenia:</strong> {moment(offer.createdAt).format('DD.MM.YYYY')}
										</li>
									))}
								</ul>
							) : (
								<p>No data available.</p>
							)}
						</div>
					)}
				</div>
			</Spin>
		</Card>

	)
}

export default PriceOffersStatusPerMonth
