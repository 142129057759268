import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import PriceOffers from './PriceOffers'
import StatisticsDashboard from './components/StatisticsDashboard'

const PriceOffersTabs = () => {
	const { t } = useTranslation('pages')

	return <Tabs defaultActiveKey="1" size={'large'}>
		<Tabs.TabPane tab={t('pages:Zoznam')} key="1">
			<PriceOffers />
		</Tabs.TabPane>
		<Tabs.TabPane tab={t('pages:Štatistiky')} key="2">
			<StatisticsDashboard />
		</Tabs.TabPane>
	</Tabs>
}

export default PriceOffersTabs
