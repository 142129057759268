import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, DatePicker, Empty, Modal, Popconfirm, Row, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { DeleteOutlined, FilePdfOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { get, isEmpty } from 'lodash'
import { getFormInitialValues, initialize } from 'redux-form'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import PriceOffersStatusPerMonth, { StatisticsDashboardSummary } from './PriceOffersCompletedPerMonth'
import SelectUserField from '../../../components/SelectUserField'
import SelectProfileField from '../../../components/SelectProfileField'
import SelectStatusField from '../../../components/SelectStatusField'
import { FORM, PRICE_OFFER_STATUS, translatePriceOfferStatusColor, translatePriceOfferStatusToSk } from '../../../utils/enums'
import { deletePriceOffer, getPriceOffers, PriceOfferTableItem } from '../../../reducers/priceOffers/actions'
import { history } from '../../../utils/history'
import { RootState } from '../../../reducers'
import { colorHash } from '../../storeItems/StoreItems'
import TableToPrint from '../../../components/QrCode/TableToPrint'

const { RangePicker } = DatePicker

const PAGE_SIZE = 30

const columnsToPrint: Columns = [
	{
		title: i18next.t('pages:Adresa'),
		dataIndex: 'address',
		key: 'address',
		ellipsis: true,
		render: (value) => value || '-'
	},
	{
		title: i18next.t('pages:Pobočka'),
		dataIndex: 'organizationName',
		key: 'organizationName',
		fixed: 'left',
		width: 150,
		render(organizationName, record) {
			return (
				<span>
					<Tag color={colorHash(organizationName)}>{ organizationName }</Tag>
				</span>
			)
		}
	},
	{
		title: i18next.t('pages:Dátum zmeny'),
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		fixed: 'right',
		width: 150,
		render(updatedAt, record) {
			return (
				<span>
					{ updatedAt }
				</span>
			)
		}
	},
	{
		title: i18next.t('pages:Status'),
		dataIndex: 'status',
		key: 'status',
		fixed: 'right',
		width: 150,
		render(status, record) {
			return (
				<span>
					<Tag color={translatePriceOfferStatusColor(status)}>{ translatePriceOfferStatusToSk(status) }</Tag>
				</span>
			)
		}
	}]

type Columns = ColumnsType<PriceOfferTableItem>

const StatisticsDashboard = () => {
	const { t } = useTranslation('pages')

	// print modal
	const [visibleGenerateItemListModal, setVisibleGenerateItemListModal] = useState(false)

	const [dateRange, setDateRange] = useState<any>([])
	// const [employeeID, setEmployeeID] = useState<number | null>(null) // State for selected employee
	const [organizationID, setOrganizationID] = useState<number | null>(null) // State for selected profile
	const [status, setStatus] = useState<PRICE_OFFER_STATUS[] >([]) // State for selected status
	const [statisticsSummary, setStatisticsSummary] = useState<StatisticsDashboardSummary>({
		NOT_ACCEPTED: 0,
		FINISHED: 0,
		DECLINED: 0,
		PRICE_INCREASE: 0,
		PRICE_INCREASE_DECLINED: 0
	})

	const dispatch = useDispatch()

	// table data
	const dataSource = useSelector((state: RootState) => state.priceOffer.priceOffers)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.PRICE_OFFERS_FILTER)(state))

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: NumberParam,
		employeeID: NumberParam,
		page: withDefault(NumberParam, 1),
		orderBy: withDefault(StringParam, 'address'),
		orderDirection: withDefault(StringParam, null)
	})

	useEffect(() => {
		dispatch(getPriceOffers(query.page, PAGE_SIZE, {
			orderBy: query.orderBy,
			employeeID: query.employeeID,
			status: Array.isArray(status) ? Array.from(status) : [], // Bezpečné spracovanie statusu
			startDate: dateRange?.[0] ? dateRange[0].startOf('month').toISOString() : null,
			endDate: dateRange?.[1] ? dateRange[1].endOf('month').toISOString() : null,
			organizationID,
			orderDirection: query.orderDirection,
			allAvailableOrganizations: true
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderBy, query.orderDirection, query.employeeID, status, dateRange, organizationID])

	useEffect(() => {
		setQuery({
			limit: PAGE_SIZE,
			...query,
			page: 1
		})
	}, [dispatch,
		query.search, query.employeeID, status, dateRange, organizationID])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.PRICE_OFFERS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const handleSummaryChange = (summary: StatisticsDashboardSummary) => {
		setStatisticsSummary(summary)
	}

	const componentRef = useRef(null)

	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string,
			FilterValue | null>,
		sorter: SorterResult<PriceOfferTableItem> | SorterResult<PriceOfferTableItem>[],
		extra: TableCurrentDataSource<PriceOfferTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			limit: PAGE_SIZE,
			...query,
			page: pagination.current,
			...order
		})
	}

	const columns: Columns = [
		{
			title: i18next.t('pages:Adresa'),
			dataIndex: 'address',
			key: 'address',
			sorter: true,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Pobočka'),
			dataIndex: 'organizationName',
			key: 'organizationName',
			fixed: 'left',
			width: 150,
			render(organizationName, record) {
				return (
					<span>
						<Tag color={colorHash(organizationName)}>{ organizationName }</Tag>
					</span>
				)
			}
		},
		{
			title: i18next.t('pages:Dátum zmeny'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			fixed: 'right',
			width: 150,
			render(updatedAt, record) {
				return (
					<span>
						{ updatedAt }
					</span>
				)
			}
		},
		{
			title: i18next.t('pages:Status'),
			dataIndex: 'status',
			key: 'status',
			fixed: 'right',
			width: 150,
			render(status, record) {
				return (
					<span>
						<Tag color={translatePriceOfferStatusColor(status)}>{ translatePriceOfferStatusToSk(status) }</Tag>
					</span>
				)
			}
		},
		{
			key: 'operation',
			fixed: 'right',
			width: 50,
			render(status, record) {
				return (
					<Popconfirm
						title={ i18next.t('pages:Skutočne chcete vymazať záznam?') }
						icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
						cancelText={ i18next.t('pages:Zrušiť') }
						okText={ i18next.t('pages:Vymazať') }
						onConfirm={ (e) => {
							e?.stopPropagation()
							dispatch(deletePriceOffer(record.id, () => {
								dispatch(getPriceOffers(1, PAGE_SIZE))
							}))
						} }
						onCancel={ (e) => e?.stopPropagation() }
						okButtonProps={ {
							size: 'small',
							type: 'primary'
						} }
						cancelButtonProps={ {
							size: 'small',
							type: 'ghost'
						} }
					>
						<Button
							icon={ <DeleteOutlined /> }
							type={ 'primary' }
							onClick={ (e) => e.stopPropagation() }
						/>
					</Popconfirm>
				)
			}
		}
	]

	useEffect(() => {
		const params: any = {
			startDate: dateRange?.[0] ? dateRange[0].startOf('month').toISOString() : null,
			endDate: dateRange?.[1] ? dateRange[1].endOf('month').toISOString() : null
		}

		if (query.employeeID) {
			params.employeeID = query.employeeID // Add employee filter to params
		}

		// Dispatch the params for filtered data
		// Example: dispatch(yourFetchStatisticsAction(params))
	}, [dateRange, query.employeeID, dispatch])

	const handleDateRangeChange = (dates: any) => {
		setDateRange(dates)
	}

	const handleEmployeeChange = (data: any) => {
		setQuery({
			...query,
			employeeID: data.value
		})
	}

	const handleProfileChange = (data: any) => {
		setOrganizationID(data.value)
	}

	const handleStatusChange = (data: PRICE_OFFER_STATUS[]) => {
		setStatus(data)
	}

	return (
		<div>
			<Row justify="space-between">
				<Col>
					<Row>
						<SelectUserField
							onValueChange={handleEmployeeChange}
							userValue={query.employeeID || undefined}
							onlyAdminRole={true}
							allAvailableOrganizations={true}
						/>
						<span className={'ml-4'}/>
						<SelectProfileField
							onValueChange={handleProfileChange}
							profileValue={organizationID || undefined}
						/>
						<span className={'ml-4'}/>
						<SelectStatusField
							onValueChange={handleStatusChange}
							statusValues={status}
						/>
					</Row>
				</Col>
				<Col>
					<Row justify="space-around">
						<RangePicker picker="month" onChange={handleDateRangeChange} />
						<span className={'ml-4'}>  </span>
						<Button
							icon={<FilePdfOutlined />}
							onClick={() => { setVisibleGenerateItemListModal(true) }}
							type={'primary'}
							style={{ margin: '0px' }}
						>
							{t('pages:Export do PDF')}
						</Button>
					</Row>
				</Col>
			</Row>

			<Row>
				<Card hoverable className={'mt-2 w-full'}>
					<span className={'font-bold'}>{t('pages:Sumár')}  </span>
					{ (statisticsSummary.NOT_ACCEPTED !== 0) && <Tag color={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.NOT_ACCEPTED)}>{ translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.NOT_ACCEPTED)} {statisticsSummary.NOT_ACCEPTED}</Tag> }
					{ (statisticsSummary.FINISHED !== 0) && <Tag color={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.FINISHED)}>{ translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.FINISHED)} {statisticsSummary.FINISHED}</Tag> }
					{ (statisticsSummary.PRICE_INCREASE !== 0) && <Tag color={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.PRICE_INCREASE)}>{ translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.PRICE_INCREASE)} {statisticsSummary.PRICE_INCREASE}</Tag> }
					{ (statisticsSummary.PRICE_INCREASE_DECLINED !== 0) && <Tag color={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED)}>{ translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED)} {statisticsSummary.PRICE_INCREASE_DECLINED}</Tag> }
					{ (statisticsSummary.DECLINED !== 0) && <Tag color={translatePriceOfferStatusColor(PRICE_OFFER_STATUS.DECLINED)}>{ translatePriceOfferStatusToSk(PRICE_OFFER_STATUS.DECLINED)} {statisticsSummary.DECLINED}</Tag> }
				</Card>
			</Row>
			<Row gutter={[16, 16]} className={'mt-6'}>
				<Col span={24}>
					<PriceOffersStatusPerMonth
						startDate={dateRange?.[0] ? dateRange[0].startOf('month').toISOString() : null}
						endDate={dateRange?.[1] ? dateRange[1].endOf('month').toISOString() : null}
						organizationID={organizationID}
						employeeID={query.employeeID}
						status={status}
						onSummaryChange={handleSummaryChange}
					/>
				</Col>
			</Row>
			<Row className={'mt-6'} gutter={[16, 16]}>
				<Col span={24}>
					<Table
						className={ 'general-table' }
						columns={ columns }
						dataSource={ dataSource.tableData }
						onChange={ handleTableChange }
						style={ { marginTop: -20 } }
						showSorterTooltip={ false }
						pagination={ {
							pageSize: PAGE_SIZE,
							total: dataSource.pagination?.totalCount,
							current: dataSource.pagination?.page,
							showSizeChanger: false
						} }
						loading={ dataSource.isLoading }
						onRow={ (record) => ({
							onClick: () => history.push(`${t('paths:priceOfferDetail|path')}/${get(record, 'id')}`)
						}) }
						locale={ {
							emptyText: (
								<Empty description={ t('pages:Žiadne dáta') } />
							)
						} }
						size={ 'small' }
					/>
				</Col>
			</Row>
			<Modal
				title={t('pages:Vytlač zoznam skladových položiek')}
				centered
				visible={visibleGenerateItemListModal}
				onOk={() => {
					setVisibleGenerateItemListModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateItemListModal(false)}
				width={1000}
			>
				<Button type={'primary'} onClick={handlePrint}>
					{t('pages:Vytlačiť')}
				</Button>
				<TableToPrint ref={componentRef}>
					<Table
						className={'general-table'}
						columns={columnsToPrint}
						dataSource={dataSource.tableData}
						onChange={handleTableChange}
						style={{ marginTop: 0 }}
						showSorterTooltip={false}
						pagination={false}
						locale={{
							emptyText: <Empty description={t('pages:Žiadne dáta')} />
						}}
						size={'small'}
					/>
				</TableToPrint>
			</Modal>
		</div>
	)
}

export default StatisticsDashboard
