import React, { useState } from 'react'
import cx from 'classnames'
import { find, map } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Empty, Popconfirm, Row, Select, Tooltip } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import i18next from 'i18next'
import { CheckCircleTwoTone, QuestionCircleOutlined, StopTwoTone } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { DeleteIcon } from '../../../components/DeleteIcon'
import { createFinish } from '../../../reducers/finishes/actions'
// eslint-disable-next-line import/no-cycle
import AddTextModal from './AddTextModal'
import PriceDeleteModal from './PriceDeleteModal'

const { Option } = Select

type Props = WrappedFieldProps & {
	label?: string,
	showLabel?: string,
	customClass?: string,
	customLabelClass?: string,
	options: Task[],
	frequency: string,
	jobId: number,
	userId: number,
	extraData: boolean,
	meta: Meta,
	setModalText: any
	handleRefresh: Function
}

type Meta = {
	touched: boolean,
	error: string
}

type TaskFrequency = {
	id?: string | null,
	frequency: string,
	order: number,
	task: Task
}

type Task = {
	id: number,
	name: string
}

const grid = 8

const frequencyToIsoDay = (day: string): number => {
	const normalizeDay = day?.toLowerCase()
	switch (normalizeDay) {
		case 'monday':
			return 1
		case 'tuesday':
			return 2
		case 'wednesday':
			return 3
		case 'thursday':
			return 4
		case 'friday':
			return 5
		case 'saturday':
			return 6
		case 'sunday':
			return 7
		default:
			return -1
	}
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const getListStyle = (isDraggingOver: any) => ({
	background: isDraggingOver ? 'lightgrey' : 'lightgrey',
	padding: grid,
	width: '100%'
})

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	// change background colour if dragging
	background: isDragging ? '#f7931d' : 'grey',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',

	// styles we need to apply on draggables
	...draggableStyle
})

const DragCustom = (
	{ onChange, value, onDelete }: DragCustomProps
) => {
	const onDragEnd = (result: any) => {
		// dropped outside the list
		if (!result.destination) {
			return
		}

		const items = reorder(
			value,
			result.source.index,
			result.destination.index
		)
		onChange(items)
	}

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{map(value, (item, index) => (
							<Draggable key={item} draggableId={`${item}`} index={index}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style
										)}
									>
										<Row justify={'space-between'}>
											<div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: item || '' }} />
										</Row>
										<DeleteIcon onClick={() => onDelete(item)}/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export interface ITextPair {
	order: number,
	descriptionWithHtml: string,
	textToReplace: string
}

const DragAndDropSelectedTextField = ({
	input,
	label,
	customClass,
	frequency,
	meta: { touched, error },
	extraData,
	jobId,
	userId,
	handleRefresh
}: Props) => {
	const dispatch = useDispatch()

	const [task, setModalTask] = useState<string | null>(null)
	const [addText, setAddText] = useState<ITextPair | null>(null)

	const texts: ITextPair[] = [
		{
			order: 0,
			descriptionWithHtml: 'Sme jediná upratovacia firma na Slovensku, ktorá využíva svoju vlastnú kontrolnú aplikáciu. Pani upratovačka má v mobilnej aplikácii zoznam všetkých zazmluvnených služieb, ktoré má vykonávať takže presne vie čo a kedy má robiť. Po uprataní vchodu príde klientovi e-mailová notifikácia, že je uprataný.',
			textToReplace: 'Sme jediná upratovacia firma na Slovensku, ktorá využíva svoju vlastnú kontrolnú aplikáciu. Pani upratovačka má v mobilnej aplikácii zoznam všetkých zazmluvnených služieb, ktoré má vykonávať takže presne vie čo a kedy má robiť. Po uprataní vchodu príde klientovi e-mailová notifikácia, že je uprataný.'
		},
		{
			order: 1,
			descriptionWithHtml: 'Každá naša upratovačka má pracovné oblečenie (tričko, mikina, tepláky, legíny...) s logom UMYJEME TOP, takže každý vie, že sa vo Vašom priestore pohybuje oprávnená osoba.',
			textToReplace: 'Každá naša upratovačka má pracovné oblečenie (tričko, mikina, tepláky, legíny...) s logom UMYJEME TOP, takže každý vie, že sa vo Vašom priestore pohybuje oprávnená osoba.'
		},
		{
			order: 2,
			descriptionWithHtml: 'Ak sa pre nás rozhodnete do <b>B.B.BBB</b> získate BONUS a to vygruntovanie Vášho vchodu v hodnote 250 eur úplne ZADARMO!',
			textToReplace: 'Ak sa pre nás rozhodnete do {REPLACE_HERE} získate BONUS a to vygruntovanie Vášho vchodu v hodnote <b>250 eur</b> eur úplne ZADARMO!'
		},
		{
			order: 3,
			descriptionWithHtml: 'Pri zazmluvnení XY vchodov cena: <b>Y,YYeur</b> / byt / mesiac',
			textToReplace: 'Pri zazmluvnení {REPLACE_HERE} vchodov cena: {REPLACE_HERE}eur / byt / mesiac'
		},
		{
			order: 4,
			descriptionWithHtml: 'Túto cenu Vám garantujeme do: <b>A.A.AAAA</b>',
			textToReplace: 'Túto cenu Vám garantujeme do: {REPLACE_HERE}'
		},
		{
			order: 5,
			descriptionWithHtml: 'Upratujeme už viac ako <b>XXX</b> vchodov. S radosťou budeme upratovať aj ten Váš.',
			textToReplace: 'Upratujeme už viac ako {REPLACE_HERE} vchodov. S radosťou budeme upratovať aj ten Váš.'
		},
		{
			order: 6,
			descriptionWithHtml: 'Každý rok 1.1 sa cena automaticky zvýši o infláciu zverejnenú Štatistickým úradom SR ku dňu 31.12. Novú cenu oznámime správcovi vlastníkov bytov a nebytových priestorov, ktorý zabezpečí jej úpravu a oboznámi vlastníkov bytov o tejto zmene.',
			textToReplace: 'Každý rok 1.1 sa cena automaticky zvýši o infláciu zverejnenú Štatistickým úradom SR ku dňu 31.12. Novú cenu oznámime správcovi vlastníkov bytov a nebytových priestorov, ktorý zabezpečí jej úpravu a oboznámi vlastníkov bytov o tejto zmene.'
		}
	]

	function getReorderedSetAccordingToOrder(newSet: string[]) {
		return newSet
			.map((additionalText: string) => {
				const startWith: string = additionalText.substring(0, 18)
				const item = texts.find(
					(it: { textToReplace: string }) => it.textToReplace.startsWith(startWith)
				)
				const order: number = item ? item.order : -1
				return {
					order,
					additionalText
				}
			})
			.sort((a, b) => a.order - b.order)
			.map((it) => it.additionalText)
	}

	const optionsSet = texts.map((text, index) => (
		<Option key={text.descriptionWithHtml.valueOf()} value={text.descriptionWithHtml}><div dangerouslySetInnerHTML={{ __html: text.descriptionWithHtml }} /></Option>
	))

	function onChangeSelect(value: string, options: any) {
		const result = find(texts, (option) => option.descriptionWithHtml === value)
		if (result) {
			setAddText(result)
		}
	}

	const onDelete = (value: string) => {
		input.onChange(input.value.filter((field: string) => field !== value))
	}

	const openModal = (value: string) => {
		setModalTask(value)
	}

	const closeModal = () => {
		setModalTask(null)
	}

	const closeAddTextModal = () => {
		setAddText(null)
	}

	const handleOk = () => {
		onDelete(task || '')
		closeModal()
	}
	const handleAddTextModalOk = (result: any) => {
		const newSet = typeof input.value === 'string' && input.value.length === 0
			? [result] : input.value.concat(result)

		const reorderedSetAccordingToOrder: string[] = getReorderedSetAccordingToOrder(newSet)
		input.onChange(reorderedSetAccordingToOrder)
		closeAddTextModal()
	}

	const dropList = input.value.length === 0
		? <Empty style={{ margin: '20px' }} description='Žiadne úlohy'/>
		: <DragCustom
			onChange={(value: string[]) => {
				// fixed place according to order in texts
				const newSet: string[] = [...value]
				const reorderedSetAccordingToOrder: string[] = getReorderedSetAccordingToOrder(newSet)
				input.onChange(reorderedSetAccordingToOrder)
			}}
			onDelete={openModal}
			value={input.value}
		/>

	const userNotDefined = userId === undefined || userId === null
	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			<div className={'input-field'}>
				<Row justify={'space-between'}>
					<h3>{label}</h3>
					{/* eslint-disable-next-line no-nested-ternary */}
					{jobId && frequency !== 'XXX' ? !extraData ? <Popconfirm
						title={i18next.t('pages:Označiť tento deň za ukončený?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
						cancelText={i18next.t('pages:Zrušiť')}
						disabled={userNotDefined}
						okText={i18next.t('pages:Pridať ukončenie')}
						onConfirm={(e) => {
							e?.stopPropagation()
							dispatch(createFinish({
								jobId,
								userId,
								executionForFrequency: frequency,
								executionForDayDate: dayjs().day((frequencyToIsoDay(frequency))).toISOString()
							}, () => {
								console.log('ukoncene uspesne')
								handleRefresh()
							}, () => {
								console.log('ukoncene neuspesne')
							}))
						}}
						onCancel={(e) => e?.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Tooltip placement="bottomLeft" title={<span>{i18next.t('pages:Ukončiť úlohu')}</span>}>
							<Button
								icon={<StopTwoTone twoToneColor="#f64747"/>}
								type={'link'}
								disabled={userNotDefined}
								onClick={(e) => e.stopPropagation()}
							/>
						</Tooltip>
					</Popconfirm> : <Tooltip placement="top" title={<span>{i18next.t('pages:Úloha je ukončená')}</span>}>
						<Button
							icon={<CheckCircleTwoTone twoToneColor="#52c41a"/>}
							type={'link'}
							disabled={true}
						/>
					</Tooltip> : <div/>
					}
				</Row>
				<Select
					showSearch
					style={{ width: '100%' }}
					placeholder="Vyber osobu"
					optionFilterProp="children"
					onChange={onChangeSelect}
					value='Vyber text'
					// onFocus={onFocus}
					// onBlur={onBlur}
					// onSearch={onSearch}
					filterOption={(input, option) => option?.children?.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
					}
				>
					{optionsSet}
				</Select>
				{dropList}
				<PriceDeleteModal text={task || ''} handleCancel={closeModal} handleOk={handleOk}/>
				<AddTextModal
					textDetail={addText}
					handleCancel={closeAddTextModal}
					handleOk={
						(string) => {
							handleAddTextModalOk(string)
						}
					}
				/>
			</div>
			<div className={'tooltip-error'}>
				{touched ? error : ''}
			</div>
		</div>
	)
}

type DragCustomProps = {
	onChange: (value: string[]) => void,
	value: string[],
	onDelete: (value: any) => void
}

export default DragAndDropSelectedTextField
