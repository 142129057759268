import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { getReq, postReq } from '../../utils/request'
import { LOAD_DASHBOARD_RATINGS, LOAD_RATINGS, POST_RATING_INVISIBLE } from './types'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'

export type ICollectionActions = IResetStore
	| IGetDashboardRatings
	| IGetRatings

// get dashboard ratings
interface IGetDashboardRatings {
	type: LOAD_DASHBOARD_RATINGS
	payload: IDashboardRatingPayload
}

export interface IDashboardRatingPayload {
	data: IDashboardRatings | null
	isLoading: boolean
	isFailure: boolean
}

export interface IDashboardRatings {
	ratings: IDashboardRating[]
}

export interface IDashboardRating {
	comment: string
	createdAt: string
	createdAtTimeStamp: number
	id: number
	job: {
		address: string,
		id: number
	},
	rating: number,
	user: {
		id: number,
		firstName: string,
		lastName: string,
		email: string
	}
}

// noinspection JSUnusedLocalSymbols
export const getDashboardRatings = (
	onSuccess: (data: IDashboardRatingPayload) => void,
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IDashboardRatingPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_DASHBOARD_RATINGS.START
	})
	try {
		const { data } = await getReq('/api/admin/dashboard/ratings')

		dispatch({
			type: LOAD_DASHBOARD_RATINGS.DONE,
			payload: data
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_DASHBOARD_RATINGS.FAILED
		})
		onFailure(error)
		return error
	}
}

// get dashboard ratings
interface IGetRatings {
	type: LOAD_RATINGS
	payload: IRatingsPayload
}

export interface IRatingsPayload {
	originalData: RatingOriginItem[] | null,
	tableData: RatingTableItem[] | []
	pagination: IResponsePagination | null
}

export interface RatingOriginItem {
	comment: string
	createdAt: string
	createdAtTimeStamp: number
	id: number
	jobName: string
	job: {
		address: string,
		id: number
	},
	taskRatings: RatingTaskDetail[],
	rating: number,
	userName: string,
	user: {
		id: number,
		firstName: string,
		lastName: string,
		email: string
	}
}

export interface RatingTableItem {
	comment: string
	createdAt: string
	userName: string,
	jobName: string,
	id: number
	taskRatings: RatingTaskDetail[],
	rating: number
}

export interface RatingTaskDetail {
	id: number
	name: string
	reaction: 'LIKE' | 'DISLIKE'
}

export const getRatings = (
	params: any
): ThunkResult<Promise<IRatingsPayload>> => async (dispatch) => {
	let payload = {} as IRatingsPayload
	try {
		dispatch({ type: LOAD_RATINGS.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/ratings', normalizeQueryParams)

		const originalData: RatingOriginItem[] = get(data, 'ratings', [])
		const tableData: RatingTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			userName: column.userName,
			comment: column.comment,
			jobName: column.jobName,
			taskRatings: column.taskRatings,
			rating: column.rating,
			createdAt: formatDateWithTime(column.createdAt)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_RATINGS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_RATINGS.FAILED })
		Promise.reject(error)
		return error
	}
}

export const postInvisibleRating = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: POST_RATING_INVISIBLE.START
	})
	try {
		await postReq(`/api/admin/ratings/${id}/invisible`)

		dispatch({ type: POST_RATING_INVISIBLE.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: POST_RATING_INVISIBLE.FAILED })
		onFailure(error)
		return error
	}
}
