import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { CREATE_TASKS, DELETE_TASK, LOAD_SELECT_TASKS, LOAD_TASK, UPDATE_TASKS } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload } from '../usersU/actions'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'

export type ITaskActions = IResetStore
	| IGetTasksActions
	| IGetTaskAction

// tasks
export interface IGetTasksActions {
	type: LOAD_SELECT_TASKS
	payload: ITasksPayload
}

export interface ITasksPayload {
	tableData: TaskTableItem[]
	originalData: TaskOriginalItem[]
	pagination: IResponsePagination | null
}

export type TaskTableItem = {
	key: number
	id: number
	createdAt: string
	name: string
	updatedAt: string
}

export type TaskOriginalItem = {
	id: number
	createdAt: string
	name: string
	updatedAt: string
}

export const getTasks = (
	page: number = 1,
	limit: number|null = 20,
	queryParams: any = {}
): ThunkResult<Promise<ITasksPayload>> => async (dispatch) => {
	let payload = {} as ITasksPayload
	try {
		dispatch({ type: LOAD_SELECT_TASKS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/tasks', normalizeQueryParams)

		const originalData: TaskOriginalItem[] = get(data, 'tasks', [])
		const tableData: TaskTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			name: column.name,
			createdAt: formatDateWithTime(column.createdAt),
			updatedAt: formatDateWithTime(column.updatedAt)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_TASKS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_TASKS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface ITaskForm {
	id: number| null
	name: string
	updatedAt: string|null
}

// noinspection JSUnusedLocalSymbols
export const createTasks = (
	values: ITaskForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_TASKS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/tasks', null, normalizeQueryParams)

		dispatch({ type: CREATE_TASKS.DONE })

		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_TASKS.FAILED })
		onFailure(error)
		return error
	}
}

export const updateTask = (
	id: number,
	values: ITaskForm,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_TASKS.START
	})
	try {
		await putReq(`/api/admin/tasks/${id}`, null, values)
		dispatch({ type: UPDATE_TASKS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_TASKS.FAILED })
		onFailure(error)
		return error
	}
}

// task
interface IGetTaskAction {
	type: LOAD_TASK
	payload: ITaskPayload
}

export interface ITaskPayload {
	data: ITaskDetail
}

export interface ITaskDetail {
	id: number
	name: string
	nameSlug: string
	createdAt: string
	updatedAt: string
	organizationID: number
}

export const getTask = (
	id: number,
	onSuccess: (data: ITaskPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<ITaskPayload>> => async (dispatch) => {
	let payload = {} as ITaskPayload
	dispatch({
		type: LOAD_TASK.START
	})
	try {
		const { data } = await getReq(`/api/admin/tasks/${id}`)

		payload = {
			data
		}

		dispatch({
			type: LOAD_TASK.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_TASK.FAILED
		})
		onFailure(error)
		return error
	}
}

export const deleteTask = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_TASK.START })
	try {
		await deleteReq(`/api/admin/tasks/${id}`)

		dispatch({ type: DELETE_TASK.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_TASK.FAILED })
		onFailure(error)
		return false
	}
}
