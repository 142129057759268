import { notification } from 'antd'
import firebase from 'firebase'
import { LOAD_PROFILE, LOGOUT_USER, SWITCH_PROFILE } from './userTypes'
import { ADMIN_ROLES_WEIGHTS } from '../../utils/enums'
// eslint-disable-next-line import/no-cycle
import { getReq, postReq, putReq } from '../../utils/request'
import { setAccessToken } from '../../utils/auth'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle

export type UserActions = IResetStore
   | IGetUserAction
   | IPostLogoutUserAction
   | IPostSwitchProfileAction
// | IGetLoginUserAction

// load profile dashboard
interface IGetUserAction {
	type: LOAD_PROFILE
	payload: IUserProfilePayload
}

export type IUserProfilePayload = {
	data: Profile | null
}

export interface Profile {
	id: number,
	availableOrganizations: Organization[],
	currentOrganization: Organization,
	email: string,
	fullName: string,
	firstName: string,
	hasDocumentsFeature: boolean,
	hasPriceForCleaningFeature: boolean,
	hasPermissionToAddStoreItems: boolean,
	hasPermissionToCreatePriceOffer: boolean,
	organizationHasItsOwnTasks: boolean,
	hasStoreItemsFeature: boolean,
	hasSuperAdminFeature: boolean,
	lastName: string,
	frequenciesWithJobs: string[],
	isConfirmed: boolean,
	isVisibleOnDashboard: boolean,
	phone: string,
	role: ADMIN_ROLES_WEIGHTS,
}

export interface Organization {
	id: number
	name: string
}

export const loadUser = (
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess : (data: Profile) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure : (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUserProfilePayload>> => async (dispatch) => {
	try {
		dispatch({ type: LOAD_PROFILE.START })

		const { data } = await getReq('api/admin/profile')

		dispatch({
			type: LOAD_PROFILE.DONE,
			payload: {
				data
			}
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({ type: LOAD_PROFILE.FAILED })
		onFailure(error)
		return error
	}
}

// get login user
interface IGetLoginUserAction {
	type: LOAD_PROFILE
	payload: IUserProfilePayload
}

export const loginUser = (
	email: string,
	password: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess : (data: Profile|null) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure : (error: any) => void = (((error) => {}))
): ThunkResult<Promise<IUserProfilePayload>> => async (dispatch) => {
	try {
		const user: firebase.auth.UserCredential = await firebase?.auth()?.signInWithEmailAndPassword(email, password)

		console.log('refresh token')
		// eslint-disable-next-line no-underscore-dangle
		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		const refreshToken = user?.user?._lat
		console.log(user)
		console.log(refreshToken)

		const token: string | undefined = await firebase.auth().currentUser?.getIdToken(true)
		if (token) {
			await setAccessToken(token)
			await dispatch(loadUser(
				(data) => {
					onSuccess(data)
				}, ((error) => {
					onFailure(error)
				})
			))
		}
		return token
	} catch (error) {
		notification.error({
			key: error.code,
			message: error.message
		})
		onFailure(error)
		return error
	}
}

// switch profile
interface IPostSwitchProfileAction {
	type: SWITCH_PROFILE
	payload: IUserProfilePayload
}

export const switchProfile = (
	organizationID: number,
	onSuccess : () => void = (() => {}),
	onFailure : (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUserProfilePayload>> => async (dispatch) => {
	try {
		dispatch({ type: SWITCH_PROFILE.START })

		const { data } = await postReq(
			'api/admin/preferences',
			null,
			{
				selectedOrganizationID: organizationID
			},
			undefined
		)

		dispatch({
			type: SWITCH_PROFILE.DONE,
			payload: {
				...data
			}
		})

		loadUser()
		onSuccess()
		return data
	} catch (error) {
		dispatch({ type: SWITCH_PROFILE.FAILED })
		onFailure(error)
		Promise.reject(error)
		return error
	}
}

export const passwordResetUser = (
	email: string,
	onSuccess : () => void = (() => {}),
	onFailure : (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUserProfilePayload>> => async (dispatch) => {
	try {
		const auth = await firebase?.auth()?.sendPasswordResetEmail(email)
		notification.success({
			message: 'Email na obnovu hesla je odoslaný'
		})
		onSuccess()
		return auth
	} catch (error) {
		notification.error({
			key: error.code,
			message: error.message
		})
		onFailure(error)
		return error
	}
}

export const setUserPasswordManually = (
	newPassword: string,
	userId: string
): ThunkResult<Promise<Boolean>> => async () => {
	try {
		await putReq(
			`/api/admin/users/${userId}/password`,
			null,
			{
				newPassword
			}
		)

		notification.success({
			message: 'Heslo úspešne zmenené'
		})
		return true
	} catch (error) {
		notification.error({
			key: error.code,
			message: error.message
		})
		return false
	}
}

export const revokeUserToken = (): ThunkResult<Promise<Boolean>> => async () => {
	try {
		await postReq(
			'/api/admin/users/revoke-token',
			null
		)

		notification.success({
			message: 'Token úspešne revoked'
		})
		return true
	} catch (error) {
		notification.error({
			key: error.code,
			message: error.message
		})
		return false
	}
}

// switch profile
interface IPostLogoutUserAction {
	type: LOGOUT_USER,
	payload: IUserProfilePayload
}

export const logoutUser = (
	onSuccess : () => void
): ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: LOGOUT_USER
	})
	onSuccess()
	return true
}
