import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

// assets
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { RootState } from '../reducers'
import { createSlug } from '../utils/helper'

const { Item } = Form
const { Option } = Select

type Props = {
	onValueChange: (value: ISelectedBranchOption) => void,
	profileValue: number | undefined
}

export interface ISelectedBranchOption {
	value: number,
	label: string
}

const SelectProfileField = (props: Props) => {
	const { onValueChange, profileValue } = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [branchSelected, setBranchSelected] = useState<number | undefined>(undefined)

	function onChange(value: number, option: any) {
		onValueChange({
			value: option?.value,
			label: option?.children
		})
		setBranchSelected(option?.value)
	}

	useEffect(() => {
		setBranchSelected(profileValue)
	}, [profileValue])

	const branches = useSelector((state: RootState) => [state.profile.profile.data?.currentOrganization]?.concat(state.profile.profile.data?.availableOrganizations) || [])
		.map((branch: any) => ({
			value: branch.id,
			label: branch.name
		}))

	return (
		<Item>
			<Select
				onBlur={() => {}}
				className={'dropDown'}
				size={'middle'}
				onChange={onChange}
				suffixIcon={<ChevronIcon width={20} height={20} />}
				showSearch
				allowClear={true}
				value={branchSelected}
				style={{ width: 200 }}
				placeholder="Vyber pobočku"
				optionFilterProp="children"
				filterOption={(input: any, option: any) => createSlug(option?.children).indexOf(createSlug(input)) >= 0}
			>
				{map(branches, (option) => (
					<Option
						key={option.value}
						value={option.value}
					>
						{option.label}
					</Option>
				))}
			</Select>
		</Item>
	)
}

export default React.memo(SelectProfileField)
