import i18next from 'i18next'

export enum NAMESPACE {
	PATHS = 'paths',
	LOC = 'loc'
}

export enum FORM_BUILDER {
	TAB_BOX = 'tabBox'
}

export enum FORM_TEMPLATE_STATUSES {
	IN_PROGRESS = 'IN_PROGRESS',
	PRODUCTION = 'PRODUCTION',
	ARCHIVED = 'ARCHIVED',
}

export enum FORM_TEMPLATE_TYPES {
	OUTBOUND = 'OUTBOUND',
	INBOUND = 'INBOUND'
}

export enum RADIO_BTN_GROUP_FIELD {
	FORM_TYPE = 'FORM_TYPE',
	TEXT_TYPE = 'TEXT_TYPE',
	POSITION = 'POSITION',
	SIZE = 'SIZE'
}

export enum FIELD_SIZE {
	S = 'S',
	M = 'M',
	L = 'L'
}

export enum LABEL_POSITION {
	LEFT = 'LEFT',
	TOP = 'TOP'
}

export enum API_RESPONSES_TYPES {
	SUCCESS = 'SUCCESS'
}

export enum FORM {
	LOGIN = 'LOGIN',
	GENERAL_SETTINGS = 'GENERAL_SETTINGS',
	PROCEDURES_SETTINGS = 'PROCEDURES_SETTINGS',
	FORM_DOCS_PAGE_FILTER = 'FORM_DOCS_PAGE_FILTER',
	DOCS_PAGE_FILTER = 'DOCS_PAGE_FILTER',
	TEMPLATES_PAGE_FILTER = 'TEMPLATES_PAGE_FILTER',
	GENERALE_TEMPLATE_ATTRIBUTES = 'GENERALE_TEMPLATE_ATTRIBUTES',
	FORM_TEMPLATE = 'FORM_TEMPLATE',
	INPUT_ELEMENT_FORM = 'INPUT_ELEMENT_FORM',

	// filters
	STORE_ITEM_FILTER = 'STORE_ITEM_FILTER',
	STORE_ITEM_REPLACEMENT_FILTER = 'STORE_ITEM_REPLACEMENT_FILTER',
    TASKS_FILTER = 'TASKS_FILTER',
    DOCUMENTS_FILTER = 'DOCUMENTS_FILTER',
    JOBS_FILTER = 'JOBS_FILTER',
    RATINGS_FILTER = 'RATINGS_FILTER',
    FINISHES_FILTER = 'FINISHES_FILTER',
    PRICE_OFFERS_FILTER = 'PRICE_OFFERS_FILTER'
}

export enum PRICE_OFFER_STATUS {
	NOT_ACCEPTED = 'NOT_ACCEPTED',
	FINISHED = 'FINISHED',
	DECLINED = 'DECLINED',
	PRICE_INCREASE = 'PRICE_INCREASE',
	PRICE_INCREASE_DECLINED = 'PRICE_INCREASE_DECLINED',
}

export const BACK_DATA_QUERY = 'backData'

export enum NOTIFICATION_TYPE {
	MODAL = 'MODAL',
	NOTIFICATION = 'NOTIFICATION'
}

export enum MSG_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum FORM_DOC_ORDER {
	QUEUE = 'queue',
	NAME = 'name',
	CREATED_AT = 'createdAt',
	UPDATED_AT = 'updatedAt',
}

export enum TEMPLATE_ORDER {
	NAME = 'name',
	CREATED_AT = 'createdAt',
	UPDATED_AT = 'updatedAt',
}

export enum FORM_DOC_STATUS {
	PRODUCTION = 'PRODUCTION',
	ARCHIVED = 'ARCHIVED',
	IN_PROGRESS = 'IN_PROGRESS'
}

export type FORM_DOC_STATUS_TYPE = FORM_DOC_STATUS.PRODUCTION | FORM_DOC_STATUS.IN_PROGRESS | FORM_DOC_STATUS.ARCHIVED

export enum PAGE {
	FORMS = 'Forms',
	TEMPLATES = 'Templates',
	USERS = 'Users',
	FORM_BUILDER = 'form-builder',
	SETTINGS = 'settings'
}

export enum BASE_MENU_TAB_PATHS {
	FORMS = 'forms',
	TEMPLATES = 'templates',
	USERS = 'users',
}

export enum PERMISSION {
	SUPER_ADMIN = 'SUPER_ADMIN',
	ADMIN = 'ADMIN'
}

export const PAGINATION = {
	defaultPageSize: 25,
	pageSizeOptions: ['25', '50', '100'],
	limit: 25 // 25 | 50 | 100
}

export const PAGINATION_USER_LIMIT = 100

export enum MAIN_LAYOUT {
	Z_INDEX = 1
}

export const EDITING_TIME_MINUTES_LIMIT = 10

export const DEFAULT_DATE_INPUT_FORMAT = 'D.M.YYYY'

export const DEFAULT_DATE_INIT_FORMAT = 'YYYY-MM-DD'

export const DEFAULT_TIME_FORMAT = 'HH:mm'

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY'

export const DEFAULT_DATE_WITH_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const DEFAULT_DATE_WITH_TIME_FORMAT_DD_MM_YYYY = 'DD.MM.YYYY'

export const DEFAULT_DATE_WITH_TIME_FORMAT_HH_MM = 'HH:mm'

export const INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT'

// umyjeme

export const FREQUENCIES_KEY = 'umyjemeFrequencies'

export interface FrequencyI {
	name: string,
	key: string,
	abbreviation: string,
}

export const FREQUENCIES: FrequencyI[] = [
	{
		name: 'Pondelok',
		abbreviation: 'Po',
		key: 'monday'
	},
	{
		name: 'Utorok',
		abbreviation: 'Ut',
		key: 'tuesday'
	},
	{
		name: 'Streda',
		abbreviation: 'St',
		key: 'wednesday'
	},
	{
		name: 'Štvrtok',
		abbreviation: 'Št',
		key: 'thursday'
	},
	{
		name: 'Piatok',
		abbreviation: 'Pi',
		key: 'friday'
	},
	{
		name: 'Sobota',
		abbreviation: 'So',
		key: 'saturday'
	},
	{
		name: 'Nedeľa',
		abbreviation: 'Ne',
		key: 'sunday'
	},
	{
		name: 'Mesačné',
		abbreviation: 'Mesiac',
		key: 'month'
	},
	{
		name: 'Štvrť-ročné (3 mesiace)',
		abbreviation: '3 mes.',
		key: 'quarterYear'
	},
	{
		name: 'Pol-ročné',
		abbreviation: '1/2 Rok',
		key: 'halfYear'
	},
	{
		name: 'Ročné',
		abbreviation: 'Rok',
		key: 'year'
	}
]

export const ADMIN_ROLES = {
	ADMIN: 'ADMIN',
	SUPERADMIN: 'SUPERADMIN'
}

export enum USER_ROLE {
	SUPERADMIN= 'SUPERADMIN',
	ADMIN= 'ADMIN',
	USER= 'USER',
	JOB_TRUSTEE= 'JOB_TRUSTEE'
}

export enum STORE_ITEM_TRANSACTION_TYPE {
	REGULAR = 'REGULAR', // from jobId to JobID
	IN = 'IN', // only to jobID available, the amount is coming out of the system
	CONSUME = 'CONSUME', // the items are consumed, destroyed,
}

export const USER_ROLES = Object.values(USER_ROLE)

export const translateRoleToSk = (role: USER_ROLE) => {
	switch (role) {
		case USER_ROLE.SUPERADMIN: return 'Superadmin'
		case USER_ROLE.ADMIN: return 'Admin'
		case USER_ROLE.USER: return 'Zamestnanec'
		case USER_ROLE.JOB_TRUSTEE: return 'Klient'
		default: return role
	}
}

export const translateStoreItemTransactionTypeToSk = (role: STORE_ITEM_TRANSACTION_TYPE) => {
	switch (role) {
		case STORE_ITEM_TRANSACTION_TYPE.IN: return 'Pripísať'
		case STORE_ITEM_TRANSACTION_TYPE.REGULAR: return 'Štandardná'
		case STORE_ITEM_TRANSACTION_TYPE.CONSUME: return 'Odpísať'
		default: return role
	}
}

export const translateStoreItemTitleTransactionTypeToSk = (role: STORE_ITEM_TRANSACTION_TYPE): string => {
	switch (role) {
		case STORE_ITEM_TRANSACTION_TYPE.IN: return 'Pripísať'
		case STORE_ITEM_TRANSACTION_TYPE.REGULAR: return 'Previesť'
		case STORE_ITEM_TRANSACTION_TYPE.CONSUME: return 'Odpísať'
		default: return role
	}
}

export const translatePriceOfferStatusToSk = (role: PRICE_OFFER_STATUS) => {
	switch (role) {
		case PRICE_OFFER_STATUS.NOT_ACCEPTED: return 'Neschválená'
		case PRICE_OFFER_STATUS.DECLINED: return 'Zamietnutá'
		case PRICE_OFFER_STATUS.FINISHED: return 'Ukončená'
		case PRICE_OFFER_STATUS.PRICE_INCREASE: return 'Navýšenie ceny schválené'
		case PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED: return 'Navýšenie ceny zamietnuté'
		default: return role
	}
}

export const translatePriceOfferStatusColor = (role: PRICE_OFFER_STATUS) => {
	switch (role) {
		case PRICE_OFFER_STATUS.NOT_ACCEPTED: return 'blue'
		case PRICE_OFFER_STATUS.DECLINED: return 'red'
		case PRICE_OFFER_STATUS.FINISHED: return 'green'
		case PRICE_OFFER_STATUS.PRICE_INCREASE: return 'orange'
		case PRICE_OFFER_STATUS.PRICE_INCREASE_DECLINED: return 'red'
		default: return role
	}
}

export enum ADMIN_ROLES_WEIGHTS {
	ADMIN= 1,
	SUPERADMIN= 0
}

export const getRouteAllowedRoles = () => ({
	[i18next.t('paths:home|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:users|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:tasks|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:ratings|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:jobs|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:finishes|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:documents|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:storeItems|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:storeItemsAmounts|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:stores|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:storeOrders|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:appSettings|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:priceOffers|key')]: ADMIN_ROLES.ADMIN,

	[i18next.t('paths:settings|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:appSuperAdmin|key')]: ADMIN_ROLES.ADMIN
})

export const DROPDOWN_POSITION = {
	BOTTOM_LEFT: {
		points: ['tl', 'bl'],
		offset: [0, 4],
		overflow: {
			adjustX: false,
			adjustY: false
		}
	}
}

export const FORMS = {
	LOGIN_FORM: 'LOGIN_FORM',
	STORE_ITEM_FORM: 'STORE_ITEM_FORM',
	STORE_ITEM_REPLACEMENT_FORM: 'STORE_ITEM_REPLACEMENT_FORM',
	SETTINGS_FORM: 'SETTINGS_FORM',
	PASSWORD_RESET_FORM: 'PASSWORD_RESET_FORM',
	TASK_FORM: 'TASK_FORM',
	DOCUMENT_FORM: 'DOCUMENT_FORM',
	DOCUMENT_CATEGORY_FORM: 'DOCUMENT_CATEGORY_FORM',
	FINISH_QR_CODE_FORM: 'FINISH_QR_CODE_FORM',
	EDIT_DOCUMENT_FORM: 'EDIT_DOCUMENT_FORM',
	CREATE_STORE_ITEM_TRANSACTION_FORM: 'CREATE_STORE_ITEM_TRANSACTION_FORM',
	JOB_FORM: 'JOB_FORM',
	STORE_FORM: 'STORE_FORM',
	STORE_ITEM_ORDER_FORM: 'STORE_ITEM_ORDER_FORM',
	STORE_MODAL_FORM: 'STORE_MODAL_FORM',
	USER_FORM: 'USER_FORM',
	PRICE_OFFER_FORM: 'PRICE_OFFER_FORM'
}

export const UPLOAD_CATEGORY = {
	PDF_DOCUMENT: 'PDF_DOCUMENT'
}
