import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { LOAD_FINISHES_PER_MONTH, LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH, LOAD_PRICE_OFFERS_SENT_PER_MONTH, LOAD_SUCCESS_RATE_PER_PERSON } from './types'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'

export type IStatisticsActions = IResetStore
	| IGetFinishesPerMonthAction
	| IGetPriceOffersSentPerMonthAction
	| IGetPriceOffersCompletedPerMonthAction
	| IGetSuccessRatePerPersonAction

// Existing Action and Payload Interfaces

export interface IGetFinishesPerMonthAction {
	type: LOAD_FINISHES_PER_MONTH
	payload: IFinishesPerMonthPayload
}

export interface IFinishesPerMonthPayload {
	finishesPerMonth: FinishesPerMonthOriginTableItem[]
}

export type FinishesPerMonthOriginTableItem = {
	count: number
	date: string
}

export const getFinishesPerMonthStats = (
	params: any = {},
	onSuccess: (data: IFinishesPerMonthPayload) => void = () => {},
	onFailure: (error: any) => void = () => {}
): ThunkResult<Promise<IFinishesPerMonthPayload>> => async (dispatch) => {
	let payload = {} as IFinishesPerMonthPayload
	try {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/finishes-per-month', normalizeQueryParams)

		const originalData: FinishesPerMonthOriginTableItem[] = get(data, 'data', [])
		const tableData: FinishesPerMonthOriginTableItem[] = map(originalData, (column) => ({
			count: column.count,
			date: column.date
		}))

		payload = {
			finishesPerMonth: tableData
		}

		dispatch({
			type: LOAD_FINISHES_PER_MONTH.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.FAILED })
		onFailure(error)
		return Promise.reject(error)
	}
}

// New Action and Payload Interfaces for Price Offer Statistics

// 1. Price Offers Sent Per Month
export interface IGetPriceOffersSentPerMonthAction {
	type: LOAD_PRICE_OFFERS_SENT_PER_MONTH
	payload: IPriceOffersSentPerMonthPayload
}

export interface IPriceOffersSentPerMonthPayload {
	priceOffersSentPerMonth: PriceOffersPerMonthOriginTableItem[]
}

export type PriceOffersPerMonthOriginTableItem = {
	count: number
	date: string
}

export const getPriceOffersSentPerMonthStats = (
	params: any = {},
	onSuccess: (data: IPriceOffersSentPerMonthPayload) => void = () => {},
	onFailure: (error: any) => void = () => {}
): ThunkResult<Promise<IPriceOffersSentPerMonthPayload>> => async (dispatch) => {
	let payload = {} as IPriceOffersSentPerMonthPayload
	try {
		dispatch({ type: LOAD_PRICE_OFFERS_SENT_PER_MONTH.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/price-offers-sent-per-month', normalizeQueryParams)

		const originalData: PriceOffersPerMonthOriginTableItem[] = get(data, 'data', [])
		const tableData: PriceOffersPerMonthOriginTableItem[] = map(originalData, (column) => ({
			count: column.count,
			date: column.date
		}))

		payload = {
			priceOffersSentPerMonth: tableData
		}

		dispatch({
			type: LOAD_PRICE_OFFERS_SENT_PER_MONTH.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({ type: LOAD_PRICE_OFFERS_SENT_PER_MONTH.FAILED })
		onFailure(error)
		return Promise.reject(error)
	}
}

// 2. Price Offers Completed Per Month
export interface IGetPriceOffersCompletedPerMonthAction {
	type: LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH
	payload: IPriceOffersCompletedPerMonthPayload
}

export interface IPriceOffersCompletedPerMonthPayload {
	priceOffersCompletedPerMonth: PriceOffersPerMonthOriginTableItem[]
}

interface OfferDetail {
	address: string;
	author: string;
	createdAt: string;
}

export interface IPriceOffersStatusPerMonthPayload {
	priceOffersStatusPerMonth: {
		date: string;
		FINISHED: number;
		NOT_ACCEPTED: number;
		DECLINED: number;
		details: {
			NOT_ACCEPTED: OfferDetail[];
			FINISHED: OfferDetail[];
			DECLINED: OfferDetail[];
		}
	}[];
	context: {
		NOT_ACCEPTED: number,
		FINISHED: number,
		DECLINED: number,
		PRICE_INCREASE: number,
		PRICE_INCREASE_DECLINED: number
	}
}

export const getPriceOffersStatusPerMonthStats = (
	params: any = {},
	onSuccess: (data: IPriceOffersStatusPerMonthPayload) => void = () => {},
	onFailure: (error: any) => void = () => {}
): ThunkResult<Promise<IPriceOffersStatusPerMonthPayload>> => async (dispatch) => {
	let payload = {} as IPriceOffersStatusPerMonthPayload
	try {
		dispatch({ type: LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH.START })

		const normalizeQueryParams = mapValues(params, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/price-offers-status-per-month', normalizeQueryParams)

		const originalData: any[] = get(data, 'data', [])
		const context: any = get(data, 'context', null)
		const tableData = originalData.map((item) => ({
			date: item.date,
			FINISHED: item.FINISHED || 0,
			NOT_ACCEPTED: item.NOT_ACCEPTED || 0,
			DECLINED: item.DECLINED || 0,
			details: item.details || {
				FINISHED: [],
				NOT_ACCEPTED: [],
				DECLINED: []
			}
		}))

		payload = {
			priceOffersStatusPerMonth: tableData,
			context: {
				NOT_ACCEPTED: context?.NOT_ACCEPTED,
				FINISHED: context?.FINISHED,
				DECLINED: context?.DECLINED,
				PRICE_INCREASE: context?.PRICE_INCREASE,
				PRICE_INCREASE_DECLINED: context?.PRICE_INCREASE_DECLINED
			}
		}

		dispatch({
			type: LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({ type: LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH.FAILED })
		onFailure(error)
		return Promise.reject(error)
	}
}

// 3. Success Rate Per Person
export interface IGetSuccessRatePerPersonAction {
	type: typeof LOAD_SUCCESS_RATE_PER_PERSON
	payload: ISuccessRatePerPersonPayload
}

export interface ISuccessRatePerPersonPayload {
	successRatePerPerson: SuccessRatePerPersonOriginTableItem[]
}

export type SuccessRatePerPersonOriginTableItem = {
	userId: number
	sent: number
	completed: number
	successRate: number
}

export const getSuccessRatePerPersonStats = (
	params: any = {},
	onSuccess: (data: ISuccessRatePerPersonPayload) => void = () => {},
	onFailure: (error: any) => void = () => {}
): ThunkResult<Promise<ISuccessRatePerPersonPayload>> => async (dispatch) => {
	let payload = {} as ISuccessRatePerPersonPayload
	try {
		dispatch({ type: LOAD_SUCCESS_RATE_PER_PERSON.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/success-rate-per-person', normalizeQueryParams)

		const originalData: SuccessRatePerPersonOriginTableItem[] = get(data, 'data', [])
		const tableData: SuccessRatePerPersonOriginTableItem[] = map(originalData, (column) => ({
			userId: column.userId,
			sent: column.sent,
			completed: column.completed,
			successRate: column.successRate
		}))

		payload = {
			successRatePerPerson: tableData
		}

		dispatch({
			type: LOAD_SUCCESS_RATE_PER_PERSON.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({ type: LOAD_SUCCESS_RATE_PER_PERSON.FAILED })
		onFailure(error)
		return Promise.reject(error)
	}
}

// Existing Organization Info Actions

export interface IOrganizationInfoPayload {
	data: IOrganizationInfoRecord[]
}

export interface IOrganizationInfoRecord {
	organizationId: number;
	name: number;
	workerCount: string;
	finishCount: string;
	date: string;
	organizationName: string;
}

export const getOrganizationInfoStats = (
	onSuccess: (data: IOrganizationInfoPayload) => void = () => {},
	onFailure: (error: any) => void = () => {}
): ThunkResult<Promise<IOrganizationInfoPayload[]>> => async (dispatch) => {
	try {
		const queries = {
			limit: 20,
			page: 1
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/all-organizations-with-stats', normalizeQueryParams)

		const originalData: IOrganizationInfoPayload[] = get(data, 'data', [])

		onSuccess(data)
		return originalData
	} catch (error) {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.FAILED })
		onFailure(error)
		return Promise.reject(error)
	}
}
