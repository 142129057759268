import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../utils/enums'
import SettingsForm from './forms/SettingsForm'
import { RootState } from '../../reducers'
import { getSettings, ISettingsDetail, updateSettings } from '../../reducers/settings/actions'

type Props = {
}

const SettingsDetail = (props: Props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state: RootState) => state.storeItems.storeItems)

	const isLoading = detail.isLoading || false

	const initDetailForm = (data: ISettingsDetail) => {
		dispatch(initialize(FORMS.SETTINGS_FORM, data))
	}

	useEffect(() => {
		dispatch(getSettings((data) => {
			const initValues: ISettingsDetail = {
				name: data.data.name,
				ceoName: data.data.ceoName,
				headers: data.data.headers
			}

			initDetailForm(initValues)
		}))
	}, [dispatch])

	const handleUpdate = (body: ISettingsDetail) => {
		dispatch(updateSettings(body, () => {
			dispatch(getSettings((data) => {
				const initData: ISettingsDetail = {
					name: data.data.name,
					ceoName: data.data.ceoName,
					headers: data.data.headers
				}
				initDetailForm(initData)
			}))
		}))
	}

	const handleSubmit = (values: ISettingsDetail) => {
		handleUpdate(values)
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<SettingsForm
					onSubmit={ handleSubmit }
				/>
			</Spin>
		</div>
	)
}

export default SettingsDetail
